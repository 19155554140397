import React, {useRef, useState, useEffect} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, Link, Box, Typography, Stack, FormHelperText } from '@mui/material';
import ArrowRight from '@mui/icons-material/ArrowForward';
import { useSearchParams, useNavigate } from 'react-router-dom';

import api from '../../api/api';
import config from '../../config';
import { getHubspotUTK } from '../../utils/utils';
import { SSOButtons } from '../SSOButtons';

export const StepOne = ({nextStepCallback}) => {
  const recaptchaRef = useRef(null);
  const [recaptchaClicked, setRecaptchaClicked] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [trackingCode, setTrackingCode] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: '',
      submit: ''
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(254)
        .required('Email is required')
    }),
    onSubmit: async (values, helpers) => {
      const recaptchaToken = recaptchaRef.current.getValue(); 
      if (recaptchaToken === '') {
        helpers.setErrors({ submit: 'Please complete the reCAPTCHA' });
        return;
      }
      try {
        const response = await api.post('/verify-email', {
          email: values.email,
          recaptcha_value: recaptchaToken,
          tracking_code: trackingCode
        });
        if(response.status === 201) {
          nextStepCallback(2, values.email.toString());
        }
        else {
          recaptchaRef.current.reset();
          helpers.setErrors({ submit: 'An unexpected error occurred. Please try again!' });
        }
      } catch (error) {
        recaptchaRef.current.reset();
        if (error.response && error.response.data && error.response.data.message) {
          helpers.setErrors({ submit: error.response.data.message });
        }else {
          helpers.setErrors({ submit: 'An unexpected error occurred. Please try again!' });
        }
        return;
      }
    }

  })

  useEffect(() => {
    const error = searchParams.get('error');
    setTrackingCode(getHubspotUTK());
    if (error) {
      navigate(window.location.pathname, { replace: true });
      setRecaptchaClicked(false);
      let errorMsg = 'Signup failed. Please try again.';
      if (error === 'non_corporate_email') {
        errorMsg = 'Please use a corporate email address';
      } else if (error === 'domain_already_registered') {
        errorMsg = 'An organization with this email domain is already registered';
      } else if (error === 'admin_already_exists') {
        errorMsg = 'This email is already registered. Please sign in to continue.';
      }

      formik.setErrors({ sso: errorMsg });
    }
  }, [formik, searchParams, navigate]);

  return (
    <Box
      sx={{
        maxWidth: 304,
        mx: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: {
          xs: 'flex-start',
          sm: 'center'
        },
        height: {
          xs: 'auto',
          sm: '100%'
        },
      }}
    >
      <Stack
        sx={{
          mb: 2,
          mt: {
            xs: 4,
            sm: 0
          }
        }}
      >
        <Typography
          component="h2"
          variant="h5"
          fontWeight={700}
          sx={{
            mb: 1
          }}
        >
          Sign up for a free trial
        </Typography>
        <Typography
          variant="body2"
          color="#6C737F"
        >
          Already have an account?
          {" "}
          <Link
            href="https://admin.keystrike.com"
            color="#2970FF"
            sx={{
              textDecoration: 'none',

              ":hover": {
                opacity: .8
              }
            }}
          >
            Sign in
          </Link>
        </Typography>
      </Stack>
        <Box
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <Stack
            spacing={2}
            sx={{
              mb: 2
            }}
          >
            <TextField
              error={!!(formik.touched.email && formik.errors.email)}
              margin="normal"
              required
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              id="email"
              label="Work email"
              placeholder="you@company.com"
              name="email"
              autoComplete="email"
              type="email"
              data-testid="emailInput"
              autoFocus
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              inputProps={{ maxLength: 254 }}
            />
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={config.recaptchaSiteKey}
              onChange={() => setRecaptchaClicked(true)}
            /> 
            {formik.errors.submit && (
              <FormHelperText
                data-testid="errorMSG"
                error
              >
                {formik.errors.submit}
              </FormHelperText>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || !recaptchaClicked}
              disableFocusRipple
              sx={{ 
                bgcolor: '#2970FF', 
                color: 'white', 
                fontWeight: 600,
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
                borderRadius: '12px',
                height: '42px',
                textTransform: 'initial',

                ":hover": {
                  boxShadow: 'none'
                }
              }}
              endIcon={<ArrowRight />}
            >
              Continue
            </Button>
          </Stack>
          {config.useGlobalIDP === 'true' && (
            <>
            <Stack spacing={2}
              sx={{
                mb: 4,
                mt: 4
              }}>
              <Typography
                variant="body2"
                color="#6C737F"
                align='center'>
                  Or continue with:
              </Typography>
              {formik.errors.sso && (
                <FormHelperText
                  data-testid="errorMSG"
                  error
                >
                  {formik.errors.sso}
                </FormHelperText>
              )}
              <SSOButtons />
            </Stack>
            </>
          )}      
          <Typography variant="body2" color="#6C737F">
            By registering you accept our 
            {" "}
            <Link 
              href="https://keystrike.com/terms"
              target="_blank"
              color="#2970FF"
              sx={{
                textDecoration: 'none',

                ":hover": {
                  opacity: .8
                }
              }}
            >
              Terms of Service
            </Link>.
          </Typography>
        </Box>
    </Box>
  );
}